<template>
  <div 
    class="loading-container w-full h-screen relative"
    :class="{'loading-started': loadingStart, 'loading-ended': loadingEnd}">
    <img class="logo" src="../assets/logos/louisxiii-white.svg" alt="">
    <div class="circles absolute">
      <img class="absolute max-w-full circle-1" src="../assets/loading/circle-1.png" alt="">
      <img class="absolute max-w-full circle-2" src="../assets/loading/circle-2.png" alt="">
      <img class="absolute max-w-full circle-2" src="../assets/loading/circle-3.png" alt="">
    </div>
    <!-- HIDDEN IMG SRC LOAD -->
    <loading-src-image />
  </div>
</template>

<script>
import LoadingSrcImage from '@/components/LoadingSrcImage'
export default {
  name: 'Loading',
  data() {
    return {
      loadingStart: false,
      loadingEnd: false
    }
  },
  components: {
    LoadingSrcImage
  },
  mounted() {
    // Loading process
    setTimeout(() => {
      this.loadingStart = true
    }, 1000)


    // Fake loading end
    setTimeout(() => {
      this.loadingEnd = true
      this.$store.dispatch('global/setImageLoaded', true);
      setTimeout(() => {
        this.$router
          .push({ name: (this.$route.params.pageRequested || this.$route.meta.nextPage), query: this.$route.query })
          .catch(console.log);
      }, 1200)
    }, 4000)
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  overflow: hidden;
  .logo, img.circle-1, img.circle-2 {
    transition: all 1.2s ease;
    opacity: 0;
  }
  .logo {
    width: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: left;
    transform: scale(0.2) translate(-50%, -50%);
    @media screen and (min-width: 768px) {
      width: 162px;
    }
  }
  .circles {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 375px;
    height: 375px;
    img.circle-1 {
      transform: scale(0.5);
    }
    img.circle-2 {
      transform: scale(0.8);
    }
    @media screen and (min-width: 600px) {
      width: 600px;
      height: 600px;
    }
    @media screen and (min-width: 940px) and (min-height: 710px) {
      width: 700px;
      height: 700px;
    }
    @media screen and (min-width: 1400px) and (min-height: 860px){
      width: 850px;
      height: 850px;
    }
  }
  &.loading-started {
    .logo, img.circle-1, img.circle-2 {
      opacity: 1;
    }
    .logo {
      transform: scale(1) translate(-50%, -50%);
    }
    img.circle-1 {
      transform: scale(0.8) rotate(-20deg);
    }
    img.circle-2 {
      transform: scale(1) rotate(20deg);
    }
  }
  &.loading-ended {
    .logo, img.circle-1, img.circle-2 {
      opacity: 0;
    }
    .logo {
      transform: scale(0.2) translate(-50%, -50%);
    }
    img.circle-1 {
      transform: scale(1) rotate(0deg);
    }
    img.circle-2 {
      transform: scale(1.2) rotate(0deg);
    }
  }
}
</style>