<template>
  <div>
    <img class="hidden" src="../assets/enigma-scroll/circle.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/circle-text.svg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/plan1.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/plan1-min.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/plan2.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/plan2-max.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/plan2-min.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/cask.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/cask-min.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/blended.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/blended-min.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/black-block.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/white-block-left.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/white-block-right.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/left-branch.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/middle-branch.png" alt="">
    <img class="hidden" src="../assets/enigma-scroll/right-branch.png" alt="">
    <img class="hidden" src="../assets/enigma-text/text.jpg" alt="">
    <img class="hidden" src="../assets/bg/enigma-reflect.jpg" alt="">
    <img class="hidden" src="../assets/bg/reveal-tasting.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/1.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/1-min.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/3.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/3-min.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/5.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/5-min.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/10.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/10-min.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/11.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/11-min.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/12.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/12-min.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/13.jpg" alt="">
    <img class="hidden" src="../assets/bg/text-mystery/13-min.jpg" alt="">
    <img class="hidden" src="../assets/enigma-scroll/plan1-max.png" alt="">


    <img class="hidden" src="/assets/monolithe/baseColor.jpg" alt="">
    <img class="hidden" src="/assets/monolithe/roughness.jpg" alt="">
    <img class="hidden" src="/assets/monolithe/monolithe.obj" alt="">
    <img class="hidden" src="/assets/cylindre/rocher.jpg" alt="">
    <img class="hidden" src="/assets/cylindre/baseColor.jpg" alt="">
    <img class="hidden" src="/assets/cylindre/code-pattern-min.jpg" alt="">
    <img class="hidden" src="/assets/cylindre/code-pattern.jpg" alt="">
    <img class="hidden" src="/assets/3dspace/baseColor1.jpg" alt="">
    <img class="hidden" src="/assets/3dspace/baseColor2.jpg" alt="">
    <img class="hidden" src="/assets/3dspace/baseColor3.jpg" alt="">
    <img class="hidden" src="/assets/3dspace/baseColor4.jpg" alt="">
  </div>
</template>

<script>
export default {
  name: 'LoadingSrcImage'
}
</script>

<style lang="scss" scoped>

</style>